@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .header-shadow {
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.12);
  }

  .custom-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 4px;
    background: #d7d7d7;
    outline: none;
    opacity: 1;
    transition: opacity 0.2s;
    border-radius: 5px;
  }

  .custom-slider:hover {
    opacity: 1;
  }

  .custom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* appearance: none; */
    width: 40px;
    height: 25px;
    background: #2a41e8;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #d7d7d7;
    content: "1";
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .custom-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #2a41e8;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .attachment-box {
    display: flex;
    margin: 0 20px 20px 0;
    background-color: #f4f4f4;
    border-radius: 4px 0px 4px 4px;
    font-weight: 600;
    padding: 15px 20px;
    padding-bottom: 45px;
    padding-right: 25px;
    line-height: 24px;
    flex-direction: column;
    color: #666;
    position: relative;
    transition: 0.3s;
    flex: 0 1 calc(50% - 21px);
    cursor: default;
    position: relative;
  }

  .attachment-box:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 20px 20px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.15) #fff;
    transition: 0.3s;
    border-radius: 0 0 0 4px;
  }
  .attachment-box span {
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    flex: auto;
  }
  .attachment-box i {
    display: block;
    font-style: normal;
    font-size: 14px;
    color: #999;
    font-weight: 500;
    margin-top: 10px;
    position: absolute;
    bottom: 10px;
    transition: 0.3s;
  }

  .note-card {
    display: flex;
    margin: 0 20px 20px 0;
    background-color: #f4f4f4;
    border-radius: 4px 0px 4px 4px;
    font-weight: 600;
    padding: 15px 20px;
    padding-bottom: 25px;
    padding-right: 25px;
    line-height: 24px;
    flex-direction: column;
    color: #666;
    position: relative;
    transition: 0.3s;
    flex: 0 1 calc(50% - 21px);
    cursor: default;
    position: relative;
  }

  .note-card:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 20px 20px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.15) #fff;
    transition: 0.3s;
    border-radius: 0 0 0 4px;
  }

  .star {
    position: relative;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: gray;
    clip-path: polygon(
      50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%
    );
  }
  .star::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: gold;
    clip-path: polygon(
      50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%
    );
    transition: width 0.3s ease;
  }
  .half::after {
    width: 50%;
  }
  .full::after {
    width: 100%;
  }

  /* ---------------------------------- */
  /* Intro Banner
------------------------------------- */
  .intro-banner {
    padding: 105px 0;
    position: relative;
  }

  .intro-banner.big-padding {
    padding: 130px 0;
  }

  .intro-banner .container {
    z-index: 100;
    position: relative;
  }

  .intro-banner:after,
  .intro-banner:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    z-index: 15;
    background: linear-gradient(
      to right,
      rgba(250, 250, 250, 1) 20%,
      rgba(250, 250, 250, 0.95) 50%,
      rgba(250, 250, 250, 0.1) 80%,
      rgba(250, 250, 250, 0) 100%
    );
  }

  .intro-banner:after {
    z-index: 5;
    background-color: #fafafa;
  }

  .intro-banner .background-image-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 60%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 90%;
    left: 10%;
    z-index: 10;
    overflow: hidden;
  }

  /* Disabled Gradient */
  .intro-banner.disable-gradient:before {
    background: #f2f2f2;
    opacity: 0.85;
  }

  .intro-banner.disable-gradient .background-image-container {
    left: 0;
    width: 100%;
  }

  .get-started-section {
    background-size: cover;
    background-position: 50%;
    display: block;
    position: relative;
  }

  .task-header:after,
  .task-header:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    z-index: 12;
    background: linear-gradient(
      to right,
      rgba(247, 247, 247, 1) 50%,
      rgba(247, 247, 247, 0.8) 70%,
      rgba(247, 247, 247, 0.8) 90%
    );
  }

  .task-header .background-image-container {
    background-size: cover;
    background-position: 100% 50%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    left: 50%;
    z-index: 12;
  }

  .get-started-section:before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #333;
    opacity: 0.65;
    display: block;
    position: absolute;
    top: 0;
  }

  .check-animation {
    animation: checkAnim 2s infinite;
  }

  @keyframes checkAnim {
    0%,
    100% {
      box-shadow: 0 0 0 5px rgba(42, 65, 232, 0.15);
    }
    50% {
      box-shadow: 0 0 0 8px rgba(42, 65, 232, 0.15);
    }
  }

  #header {
    z-index: 999;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.12);
  }

  .thumb .thumb-right::before {
    content: "";
    background-color: inherit;
    position: absolute;
    width: 20px;
    height: 20px;
    border: solid 1px #2a41e8 !important;
    border-radius: 50%;
    z-index: 1;
    margin: -8px -12px;
    cursor: pointer;
  }

  .yt-content-item {
    background: #ccc;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: 100%;
    display: block;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    height: 460px;
    z-index: 100;
    cursor: pointer;
    transition: 0.4s;
  }

  @media (max-width: 1366px) {
    .yt-content-item {
      height: 420px;
    }
  }

  .yt-content-item img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 4px;
  }

  .yt-content-item:before {
    content: "";
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    border-radius: 4px;
    background: linear-gradient(
      to top,
      rgba(51, 51, 51, 0.8) 0%,
      rgba(51, 51, 51, 0.7) 35%,
      rgba(51, 51, 51, 0.3) 60%,
      rgba(51, 51, 51, 0.3) 100%
    );
    transition: 0.4s;
  }

  /* :root {
    --rt-color-white: #fff;
    --rt-color-dark: #222;
    --rt-color-success: #8dc572;
    --rt-color-error: #be6464;
    --rt-color-warning: #f0ad4e;
    --rt-color-info: #337ab7;
    --rt-opacity: 0.9;
    --rt-transition-show-delay: 0.15s;
    --rt-transition-closing-delay: 0.15s;
  } */
}
